export default {
  heading: 'Ysabeau, sans-serif',
  subheading: 'Ysabeau, sans-serif',
  body: 'Ysabeau, sans-serif',
  monospace: 'Ysabeau, sans-serif',
  display: 'Ysabeau, sans-serif',
  googleFonts: [
    'Ysabeau',
    'Shippori Mincho:400,500,600,700,800',
    'Handlee',
    'Lato:100,200,300,400,500,600,700,800,900',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
