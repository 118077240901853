export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem !important',
      padding: '0rem'
    },
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        filter: 'unset',
        maxHeight: ['50px', '60px', '70px', '70px']
        // maxWidth: ['', '', 'unset', 'unset'],
      }
    },
    '.logoScrolled': {
      display: 'flex',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['50px', '60px', '70px', '70px'],
        padding: '0rem'
      }
    },

    '.container': {
      backgroundColor: 'primary',
      position: 'fixed',
      padding: '0.5rem'
    },
    '.containerScrolled': {
      backgroundColor: 'primary',
      padding: '0.5rem',
      '.hamburger': {
        display: ['', '', '', 'none'],
        borderRadius: '10px',
        borderColor: 'white',
        '> div': {
          backgroundColor: 'light'
        }
      }
    },
    '.smallNavMenu': {
      margin: '0rem 0rem 0rem auto',
      '.navItem': {
        // textShadow: '1px 1px 10px black',
        color: 'white',
        a: {
          fontSize: '1rem',
          fontFamily: 'heading',
          textTransform: 'uppercase',
          ':hover': {
            textDecoration: 'underline',
            color: 'white'
          }
        }
      }
    },
    '.hamburger': {
      display: ['', '', '', 'none'],
      borderRadius: '6px',
      borderColor: 'white',
      '>div': {
        backgroundColor: 'white'
      }
    },

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    }
  },

  footer: {
    alignItems: 'center',
    justifyContent: 'space-between',

    backgroundColor: 'primary',
    border: 'none',
    '.footerContainer': {
      backgroundColor: 'primary'
    },
    '.image': {},
    '.multiButtonContainer': {},
    '.contactDetails-container': {},
    '.copyright': {},
    '.socialIconsContainer': {},
    '.gonationLogo': {},
    '.logo': {
      maxWidth: 275
    },
    '.column': { padding: '1rem', border: 'none!important' },
    '.poweredByText': {
      justifyContent: 'flex-start',
      filter: 'brightness(0) invert(1)',
      margin: 'auto'
    }
  },

  ctaWidget: {
    display: ['', '', '', 'none'],
    '.ctaWidgetContainer': {
      display: ['', '', '', 'none']
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '2.75rem', '2.75rem', '3rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    order: '1',
    textTransform: 'uppercase',
    position: 'relative',
    paddingBottom: '0.75rem',
    '::after': {
      //   content: "''",
      position: 'absolute',
      bottom: '0rem',
      left: '0rem',
      height: '6px',
      width: '90px',
      backgroundColor: '#ded3ba'
    }
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.1rem', '1.2rem', '1.5rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontFamily: 'body',
    color: 'primary'
  },
  text: {
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'left'
    }
  },

  sideBySide: {
    minHeight: ['', '', '70vh'],
    '.lazyload-wrapper': {
      maxHeight: '75vh',
      justifyContent: 'flex-end'
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageCenterBlock: {
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    minHeight: '90vh',
    '.section': {
      margin: 'auto',
      padding: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: ['', '', '', '90%']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white',
      '::after': {
        // content: ''
      }
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'left',
      maxWidth: '700px',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    position: 'relative',
    overflow: 'hidden',
    '.slick-slider': {
      '.slick-initialized .slick-slide > div': {
        height: 'calc(100vh - 2rem)'
      },
      '.slick-arrow': {
        backgroundColor: 'primary',
        color: 'white',
        padding: '1rem',
        height: '60px',
        width: '60px',
        opacity: '0.8'
      },
      '.slick-next': {
        right: '0px'
      },
      '.slick-slide': {
        img: {
          filter: ['brightness(0.50)', 'brightness(0.50)', 'brightness(0.80)', 'brightness(0.80)']
        }
      },
      '.slick-dots': {
        bottom: ['3rem', '4rem'],
        li: {
          button: {
            '::before': {
              color: 'primary',
              fontSize: '1rem'
            }
          }
        }
      }
    },

    '.hero_content_container': {
      position: 'absolute',
      top: 'calc(50% + 42.5px)',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      // textShadow: '1px 1px 10px black',
      width: '95%',
      maxWidth: '1000px',
      textAlign: 'center',
      margin: '0rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    p: {
      maxWidth: 600,
      lineHeight: 1.5
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3rem', '3.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white',
      '::after': {
        // content: ''
      }
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center',
        fontSize: ['1.125rem', '1.2rem', '1.3rem', '1.4rem', '1.5rem']
      }
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    marginTop: '1rem',
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],

      fontFamily: 'heading',
      width: ['', '', '70%'],
      padding: '1rem',
      textTransform: 'uppercase'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: '#f3ebd9',
      color: '#484343'
    },
    '.date': {
      backgroundColor: '#f3ebd9',
      color: '#000',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'none'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageBoxes: {
    padding: ['2rem', '', '', '0rem 2rem 3rem'],
    margin: '0rem',
    '.box': {
      width: ['100%', '', 'calc(50% - 3rem)', 'calc(33.3% - 3rem)'],
      margin: '1.5rem',
      border: 'solid 1px #efefef',
      transition: 'all ease-in-out 1s',
      ':hover': {
        border: 'solid 1px black',
        '.image': {
          filter: 'grayscale(100%)'
        },
        '.title': {
          borderBottom: 'solid 2px black'
        }
      },
      '.image': {
        height: ['auto', '', '350px'],
        objectFit: 'cover',
        transition: 'all ease-in-out 1s'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem', '2.25rem'],
        transition: 'all ease-in-out 1s',
        borderBottom: 'solid 2px #efefef',
        paddingBottom: '0.5rem',
        '::after': {
          //   content: ''
        }
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontWeight: '300',
        color: '#7d725a'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      ul: {
        marginBlockStart: '0rem',
        paddingInlineStart: '1rem',
        li: {
          fontSize: '0.9rem',
          marginBottom: '0.25rem'
        }
      }
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      justifyContent: 'stretch'
    },
    '.albumsCTA': {
      border: 'none',
      flex: 1,
      margin: `0!important`,
      borderRadius: `0!important`,
      backgroundColor: 'transparent',
      color: 'primary',
      border: '1px solid',
      borderColor: 'primary'
    },
    '.albumTitle': {
      padding: '0.5rem',
      backgroundColor: 'transparent',
      borderRadius: 0,
      color: 'primary',
      border: 'none!important',
      borderWidth: `0!important`,
      textDecoration: 'none',
      fontFamily: 'primary',
      textTransform: 'none'
    },
    '.albumsCTAActive': {
      border: 'none',
      margin: `0!important`,
      borderRadius: `0!important`,
      backgroundColor: 'primary',
      color: 'white',
      border: '1px solid',
      borderColor: 'primary',
      flex: 1,
      '.albumTitle': {
        color: 'white',
        border: 0,
        borderBottom: 'none!important',
        textDecoration: 'none',
        fontFamily: 'primary',
        textTransform: 'none',
        borderBottomColor: `transparent!important`
      }
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        filter: 'brightness(1) invert(1)'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    // display: 'none',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? =============================
  // ? ====  Reservations page  ====
  // ? =============================

  reservations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20vh 1rem',

    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      fontSize: ['2rem', '', '2.5rem'],
      fontWeight: '400'
    }
  },

  // ? =============================
  // ? ====  Reservations page  ====
  // ? =============================

  privateEventsHero: {
    height: '70vh',
    '.slick-slider': {
      height: '70vh',
      '.slick-list': { height: '70vh' },
      '.slick-initialized .slick-slide > div': {
        height: '70vh',
        img: {
          height: '70vh'
        }
      },
      '.slick-arrow': {
        display: 'none'
      }
    },
    overflow: 'hidden',

    '.hero_content_container': {
      position: 'absolute',
      top: 'unset',
      bottom: '5vh',
      left: '0%',
      // transform: 'translate(-50%,-50%)',
      marginLeft: 'unset',
      color: 'black',
      fontSize: ['2.5rem', '', '4.5rem'],
      marginBottom: 1,
      letterSpacing: '3px',
      borderColor: 'secondary',
      // backgroundColor: 'rgba(0,0,0,0.35)',
      fontFamily: 'display',
      fontWeight: 'normal',
      color: 'light',
      textShadow: '2px 2px 2px black',
      backgroundColor: 'rgb(29 33 68 / 84%)',
      padding: '1.5rem 3rem',
      borderBottom: '#5095b2 solid 8px'
    },
    '.ctaPhoneAndAddress': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  blogPage: {},

  // ? ========================
  // ? =====  services   ======
  // ? ========================

  servicesMenu: {
    '.menuSectionTitle': {
      display: 'none'
    },
    '.menuSectionDescription': {
      padding: '2rem',
      backgroundColor: 'primary',
      margin: '0rem 0rem 2rem',
      color: 'white'
    },
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', 'calc(50% - 1rem)', '', 'calc(25% - 1rem)'],
      margin: ['', '0.5rem', '', '0.5rem']
    },
    '.menuItemImageContainer': {
      display: 'block',
      padding: '0.25rem',
      border: 'solid 1px',
      borderColor: 'primary',
      borderRadius: '0px',
      img: {
        padding: '0.25rem'
      }
    },
    '.itemImageFill': {
      paddingBottom: '100%'
    },
    '.menuItemContentContainer': {
      zIndex: '1',
      '::before': {
        content: "''",
        border: 'solid 1px',
        borderColor: 'grey',
        width: 'calc(100% + 0.5rem)',
        height: 'calc(100% + 0.5rem)',
        position: 'absolute',
        left: '-0.25rem',
        top: '-0.25rem',
        zIndex: '-1'
      },
      position: 'relative',
      top: '-3rem',
      padding: '1rem',
      backgroundColor: 'grey',
      width: '90%',
      textAlign: 'center',
      margin: '0rem auto',
      backgroundColor: 'rgba(250, 248, 247, 0.9)',
      padding: '0.5rem'
    },
    '.menuItemName': {
      borderBottom: 'dashed 1px',
      paddingBottom: '0.5rem'
    },

    '.menuItemDescription': {
      fontSize: '0.8rem',
      width: '100%',
      textTransform: 'capitalize'
    }
  }
}
