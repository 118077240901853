export default {
  text: '#0c3450',
  primary: '#1d2144',
  secondary: '#ababab',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303',
  primaryDark: '#1c2144'
}
